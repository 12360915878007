import React, { ForwardedRef, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import getWindowDimensions from "../../../hooks/useWindowDimension";
import MobileIcon from "../../../assets/images/svg/circle-block-mobile-icon.svg";

gsap.registerPlugin(ScrollTrigger);

const CircleBlock: React.FC = () => {
  const windowWidth = getWindowDimensions().width;
  const circleContainerRef = useRef();
  const circle1Ref = useRef();
  const circle2Ref = useRef();
  const circle3Ref = useRef();
  const circle4Ref = useRef();
  const circle5Ref = useRef();
  const circle6Ref = useRef();

  const item2Ref = useRef();
  const item4Ref = useRef();
  const item5Ref = useRef();

  const fly1Ref = useRef();
  const fly2Ref = useRef();
  const fly3Ref = useRef();
  const fly4Ref = useRef();
  const fly5Ref = useRef();
  const fly6Ref = useRef();
  const fly7Ref = useRef();

  const circleAnimationOptions = {
    width: "120vh",
    height: "120vh",
    opacity: 0,
    duration: 4,
  };

  useEffect(() => {
    if (windowWidth > 968) {
      const circleDeskTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: circleContainerRef.current,
          scrub: 1,
          pin: true,
          pinSpacing: true,
          start: "top top",
          end: "+=330% bottom",
        },
      });

      circleDeskTimeline
        .to(circle1Ref.current, circleAnimationOptions)
        .to(circle2Ref.current, circleAnimationOptions, 1)
        .to(circle3Ref.current, circleAnimationOptions, 2)
        .to(circle4Ref.current, circleAnimationOptions, 3)
        .to(circle5Ref.current, circleAnimationOptions, 4)
        .to(circle6Ref.current, circleAnimationOptions, 5)
        // Items
        .to(
          item2Ref.current,
          {
            x: "24%",
            duration: 4,
          },
          0
        )
        .to(
          item4Ref.current,
          {
            x: "-24%",
            duration: 4,
          },
          0
        )
        .to(
          item5Ref.current,
          {
            x: "10%",
            duration: 4,
          },
          0
        )
        //Flying items
        .to(
          fly1Ref.current,
          {
            x: "-10%",
            y: "-30%",
            duration: 10,
          },
          0
        )
        .to(
          fly2Ref.current,
          {
            x: "20%",
            y: "-35%",
            duration: 10,
          },
          0
        )
        .to(
          fly3Ref.current,
          {
            x: "85%",
            y: "-10%",
            duration: 10,
          },
          0
        )
        .to(
          fly4Ref.current,
          {
            x: "-55%",
            duration: 10,
          },
          0
        )
        .to(
          fly5Ref.current,
          {
            x: "-55%",
            y: "35%",
            duration: 10,
          },
          0
        )
        .to(
          fly6Ref.current,
          {
            x: "55%",
            y: "35%",
            duration: 10,
          },
          0
        )
        .to(
          fly7Ref.current,
          {
            x: "-55%",
            y: "-35%",
            duration: 10,
          },
          0
        );
    } else {
      const circleMobTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: circleContainerRef.current,
          scrub: 1,
          pin: true,
          pinSpacing: true,
          start: "-20% top",
          end: "+=2000 bottom",
        },
      });
      circleMobTimeline
        .to(circle1Ref.current, circleAnimationOptions)
        .to(circle2Ref.current, circleAnimationOptions, 1)
        .to(circle3Ref.current, circleAnimationOptions, 2)
        .to(circle4Ref.current, circleAnimationOptions, 3)
        .to(circle5Ref.current, circleAnimationOptions, 4)
        .to(circle6Ref.current, circleAnimationOptions, 5) //Items
        .to(
          item2Ref.current,
          {
            x: "24%",
            duration: 4,
          },
          0
        )
        .to(
          item4Ref.current,
          {
            x: "-24%",
            duration: 4,
          },
          0
        )
        .to(
          item5Ref.current,
          {
            x: "10%",
            duration: 4,
          },
          0
        )
        //Flying items
        .to(
          fly1Ref.current,
          {
            x: "-10%",
            y: "-30%",
            duration: 10,
          },
          0
        )
        .to(
          fly2Ref.current,
          {
            x: "20%",
            y: "-35%",
            duration: 10,
          },
          0
        )
        .to(
          fly3Ref.current,
          {
            x: "85%",
            y: "-10%",
            duration: 10,
          },
          0
        )
        .to(
          fly4Ref.current,
          {
            x: "-55%",
            duration: 10,
          },
          0
        )
        .to(
          fly5Ref.current,
          {
            x: "-55%",
            y: "35%",
            duration: 10,
          },
          0
        )
        .to(
          fly6Ref.current,
          {
            x: "55%",
            y: "35%",
            duration: 10,
          },
          0
        )
        .to(
          fly7Ref.current,
          {
            x: "-55%",
            y: "-35%",
            duration: 10,
          },
          0
        );
    }
  }, []);

  return (
    <Root ref={circleContainerRef}>
      <div className="container">
        <Inner>
          <Wave ref={circle1Ref} />
          <Wave ref={circle2Ref} />
          <Wave ref={circle3Ref} />
          <Wave ref={circle4Ref} />
          <Wave ref={circle5Ref} />
          <Wave ref={circle6Ref} />

          <MainWrapper>
            <StyledMobileIcon />
            <MobileInner>
              <MobileItemWrapper>
                <StaticImage
                  src="../../../assets/images/circle-block-mobile-item-1.png"
                  alt="mobile card item 1"
                  placeholder="none"
                />
              </MobileItemWrapper>
              <MobileItemWrapper ref={item2Ref}>
                <StaticImage
                  src="../../../assets/images/circle-block-mobile-item-2.png"
                  alt="mobile card item 1"
                  placeholder="none"
                />
              </MobileItemWrapper>
              <MobileItemWrapper>
                <StaticImage
                  src="../../../assets/images/circle-block-mobile-item-3.png"
                  alt="mobile card item 1"
                  placeholder="none"
                />
              </MobileItemWrapper>
              <MobileItemWrapper ref={item4Ref}>
                <StaticImage
                  src="../../../assets/images/circle-block-mobile-item-4.png"
                  alt="mobile card item 1"
                  placeholder="none"
                />
              </MobileItemWrapper>
              <MobileItemWrapper ref={item5Ref}>
                <StaticImage
                  src="../../../assets/images/circle-block-mobile-item-5.png"
                  alt="mobile card item 1"
                  placeholder="none"
                />
              </MobileItemWrapper>
              <MobileItemWrapper>
                <StaticImage
                  src="../../../assets/images/circle-block-mobile-item-6.png"
                  alt="mobile card item 1"
                  placeholder="none"
                />
              </MobileItemWrapper>
            </MobileInner>

            <FlyingItem1 ref={fly1Ref}>
              <StaticImage
                src="../../../assets/images/circle-block-flying-item-1.png"
                alt="flying mobile card item"
                placeholder="none"
              />
            </FlyingItem1>
            <FlyingItem2 ref={fly2Ref}>
              <StaticImage
                src="../../../assets/images/circle-block-flying-item-2.png"
                alt="flying man portrait 1"
                placeholder="none"
              />
            </FlyingItem2>
            <FlyingItem3 ref={fly3Ref}>
              <StaticImage
                src="../../../assets/images/circle-block-flying-item-3.png"
                alt="flying man portrait 2"
                placeholder="none"
              />
            </FlyingItem3>
            <FlyingItem4 ref={fly4Ref}>
              <StaticImage
                src="../../../assets/images/circle-block-flying-item-4.png"
                alt="flying man portrait 3"
                placeholder="none"
              />
            </FlyingItem4>
            <FlyingItem5 ref={fly5Ref}>
              <StaticImage
                src="../../../assets/images/circle-block-flying-item-5.png"
                alt="flying man portrait 4"
                placeholder="none"
              />
            </FlyingItem5>
            <FlyingItem6 ref={fly6Ref}>
              <StaticImage
                src="../../../assets/images/circle-block-flying-item-6.png"
                alt="flying man portrait 5"
                placeholder="none"
              />
            </FlyingItem6>
            <FlyingItem7 ref={fly7Ref}>
              <StaticImage
                src="../../../assets/images/circle-block-flying-item-7.png"
                alt="flying man portrait 6"
                placeholder="none"
              />
            </FlyingItem7>
          </MainWrapper>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section`
  position: relative;
  width: 100%;
`;
const MainWrapper = styled.div`
  position: relative;
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  /*  padding: 10% 0; */
  @media (max-width: 768px) {
    min-height: 500px;
    padding: 50px 0;
  }
`;

const Wave = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: -1;
  width: 1vw;
  height: 1vw;
  margin: auto;
  border: 3px solid #0184fd;
  border-radius: 50%;
`;

const StyledMobileIcon = styled(MobileIcon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  @media (max-width: 768px) {
    max-width: 210px;
  }
`;

const MobileInner = styled.div`
  max-width: 340px;
  width: 100%;
  margin-top: 111px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    max-width: 210px;
    margin-top: 55px;
  }
`;

const MobileItemWrapper = styled.div`
  position: relative;
  margin-bottom: 14px;
  width: 100%;
  border-radius: 18px;
  box-shadow: 0px 3.5px 14.5px rgba(40, 37, 37, 0.1);
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 6px;
    max-width: 176px;
  }
`;

const FlyingItemStyle = css`
  position: absolute;
  border-radius: 50%;
`;

const FlyingItem1 = styled.div`
  ${FlyingItemStyle}
  top: -11.5%;
  left: -31%;
  max-width: 290px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const FlyingItem2 = styled.div`
  ${FlyingItemStyle}
  top: -6.5%;
  right: -34%;
  max-width: 95px;
  @media (max-width: 768px) {
    max-width: 52px;
  }
`;

const FlyingItem3 = styled.div`
  ${FlyingItemStyle}
  top: 38.2%;
  right: -48%;
  max-width: 50px;

  @media (max-width: 768px) {
    width: 42px;
  }
`;

const FlyingItem4 = styled.div`
  ${FlyingItemStyle}
  top: 43.3%;
  left: -28%;
  max-width: 70px;

  @media (max-width: 768px) {
    max-width: 60px;
    left: -40%;
  }
`;

const FlyingItem5 = styled.div`
  ${FlyingItemStyle}
  top: 74.5%;
  left: -46%;
  max-width: 120px;

  @media (max-width: 768px) {
    max-width: 68px;
  }
`;

const FlyingItem6 = styled.div`
  ${FlyingItemStyle}
  top: 83.7%;
  right: -49.3%;
  max-width: 122px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FlyingItem7 = styled.div`
  ${FlyingItemStyle}
  top: 23.7%;
  left: -39%;
  max-width: 83px;

  @media (max-width: 768px) {
    max-width: 48px;
  }
`;

export default CircleBlock;
