import * as React from "react";
import styled from "styled-components";

import Ball1 from "../../../assets/images/svg/find-people-block-ball-1.svg";
import Ball2 from "../../../assets/images/svg/find-people-block-ball-2.svg";
import Ball3 from "../../../assets/images/svg/find-people-block-ball-3.svg";
import Ellipse from "../../../assets/images/svg/find-people-block-ellipse-1.svg";
import FindPeopleIconForMobile from "../../../assets/images/svg/find-people-block-for-mobile.svg";

import { FindPeopleIcon } from "../../../ui/icons";

interface FindPeopleProps {
  findPeopleTitle: string;
  findPeopleDescription: string;
}

const FindPeople: React.FC<{ findPeopleBlockProps: FindPeopleProps }> = ({
  findPeopleBlockProps,
}) => {
  const { findPeopleTitle, findPeopleDescription } = findPeopleBlockProps;

  return (
    <Root>
      <div className="container">
        <Inner>
          <InfoWrapper>
            <Title>{findPeopleTitle || "Find people title"}</Title>
            <Content>
              {findPeopleDescription || "Find people description"}
            </Content>
          </InfoWrapper>
          <Image1Wrapper>
            <FindPeopleIcon />
          </Image1Wrapper>
          <Image2Wrapper>
            <FindPeopleIconForMobile />
          </Image2Wrapper>
          <StyledBall1>
            <Ball1 />
          </StyledBall1>
          <StyledBall2>
            <Ball2 />
          </StyledBall2>
          <StyledBall3>
            <Ball3 />
          </StyledBall3>
          <EllipseWrapper>
            <Ellipse />
          </EllipseWrapper>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section`
  width: 100%;
  min-height: 100vh;
`;

const Inner = styled.div`
  position: relative;
  padding: 0 3.1%;
  padding-top: 135px;
  padding-bottom: 105px;
  @media (max-width: 768px) {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 68px;
  }
`;

const InfoWrapper = styled.div`
  padding: 0 5.6%;
  margin-bottom: 65.5px;
  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 39px;
  line-height: 46.22px;
  color: var(--gray3);
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Content = styled.p`
  width: 40%;
  margin-top: 26px;
  font-size: 18px;
  line-height: 25.2px;
  color: var(--gray3);
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const Image1Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 1%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Image2Wrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    * {
      margin: 0 auto;
    }
    display: block;
  }
`;

const StyledBall1 = styled.div`
  position: absolute;
  top: 28.3%;
  left: 70%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledBall2 = styled.div`
  position: absolute;
  top: 33.5%;
  left: 48%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledBall3 = styled.div`
  position: absolute;
  top: -3.35%;
  left: 57%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const EllipseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 64.5%;
  z-index: -1;
  @media (max-width: 768px) {
    display: none;
  }
`;

export default FindPeople;
