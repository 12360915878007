import { useState, useEffect } from "react";

const isSSR = typeof window === "undefined";

function getWindowDimensions() {
  if (!isSSR) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  return { width: 0, height: 0 };
}

export default function useWindowDimensions() {
  if (!isSSR) {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    return windowDimensions;
  }
  return { width: false, height: false };
}
