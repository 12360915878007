import React, { FC } from "react";
import styled from "styled-components";

import { Footer, Header, Seo } from "../../components";
import { LocaleContext } from "../../context/localeContext";
import { HeaderLink, FooterSocialLink, FooterData } from "../../interfaces";

type MainLayoutProps = {
  locale: string;
  seo?: any;
  headerLinks: HeaderLink[];
  footerData: FooterData;
  footerSocialLinks: FooterSocialLink[];
};

const MainLayout: FC<MainLayoutProps> = ({
  children,
  locale,
  seo,
  headerLinks,
  footerData,
  footerSocialLinks,
}) => {
  return (
    <LocaleContext.Provider value={locale}>
      <MainLayoutWrapper className={`lang-${locale}`}>
        <Seo seo={seo} locale={locale} />
        <Header locale={locale} headerLinks={headerLinks} />
        <Main>{children}</Main>
        <Footer
          locale={locale}
          footerData={footerData}
          footerSocialLinks={footerSocialLinks}
        />
      </MainLayoutWrapper>
    </LocaleContext.Provider>
  );
};

const Main = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MainLayoutWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
`;

export default MainLayout;
