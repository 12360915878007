import React, { FC } from "react";
import styled from "styled-components";

import { FooterData, FooterSocialLink } from "../../interfaces";
import { FooterLogoIcon } from "../../ui/icons";

interface FooterProps {
  locale: string;
  footerData: FooterData;
  footerSocialLinks: FooterSocialLink[];
}

const Footer: FC<FooterProps> = ({ locale, footerData, footerSocialLinks }) => {
  const currentYear = new Date().getFullYear();

  const socialLinks = footerSocialLinks
    .sort((first, second) => first.sort - second.sort)
    .map((value) => (
      <SocialWebItem key={`social-${value.id}`}>
        <a href={value.href}>
          <img
            src={value.iconImage.localFile.publicURL}
            alt={value.iconImage.alternativeText}
          />
        </a>
      </SocialWebItem>
    ));

  return (
    <Root id="footer">
      <div className="container">
        <Inner>
          <StyledFooterLogoIcon />
          <FooterGrid>
            <GridColumn>
              <ColumnHeader>
                {footerData.contactsColumnTitle || "Contacts"}
              </ColumnHeader>
              <ColumnContent>
                <a href={`mailto:${footerData.contactEmail}`}>
                  {footerData.contactEmail}
                </a>
              </ColumnContent>
              <ColumnContent>
                <a href={`tel:${footerData.contactPhone}`}>
                  {footerData.contactPhoneLabel}
                </a>
              </ColumnContent>
            </GridColumn>
            <GridColumn>
              <ColumnHeader>
                {footerData.officeColumnTitle || "Office"}
              </ColumnHeader>
              <ColumnParagraphContent>
                {footerData.officeAddress || "Address"}
              </ColumnParagraphContent>
            </GridColumn>
            <GridColumn>
              <ColumnHeader>
                {footerData.socialWebsColumnTitle || "Social Webs"}
              </ColumnHeader>
              <SocialWebsContainer>{socialLinks}</SocialWebsContainer>
            </GridColumn>
          </FooterGrid>
          <CopyRight>
            © Fllint.{" "}
            {locale === "ru" ? "Все права защищены." : "All rights reserved."}{" "}
            {currentYear}
          </CopyRight>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.footer`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 80px;
  z-index: 50;
`;

const Inner = styled.div`
  padding: 0 5.8%;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledFooterLogoIcon = styled(FooterLogoIcon)`
  margin-bottom: 3.8%;
  @media (max-width: 768px) {
    width: 21.7%;
  }
`;

const FooterGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 74px;
  @media (max-width: 1000px) {
    margin-top: 10%;
    margin-bottom: 89px;
    flex-direction: column;
  }
`;

const GridColumn = styled.div`
  flex: 0 1 32%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 12px;
  }
`;

const ColumnHeader = styled.h3`
  font-weight: normal;
  font-size: 28px;
  line-height: 33.18px;
  color: var(--black);
  margin-bottom: 27px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }
`;

const ColumnContent = styled.div`
  line-height: 18.96px;
  color: var(--gray1);
  margin-bottom: 12px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const ColumnParagraphContent = styled.p`
  width: 70%;
  line-height: 18.96px;
  color: var(--gray1);
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const SocialWebsContainer = styled.ul`
  display: flex;
`;

const SocialWebItem = styled.li`
  width: 24px;
  height: auto;
  transition: transform 0.5s;
  :hover {
    transform: scale(1.2);
  }
  :not(:first-child) {
    margin-left: 20px;
  }
`;

const CopyRight = styled.span`
  color: var(--gray2);
`;

export default Footer;
