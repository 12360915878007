import * as React from "react";
import styled from "styled-components";

import MeetBlockIcon from "../../../assets/images/svg/meet-block-icon.svg";
import { MeetIconForMobile } from "../../../ui/icons";

import Cloud1 from "../../../assets/images/svg/meet-block-cloud-1.svg";
import Cloud2 from "../../../assets/images/svg/meet-block-cloud-2.svg";
import Cloud3 from "../../../assets/images/svg/meet-block-cloud-3.svg";

import Grass1 from "../../../assets/images/svg/meet-block-grass-1.svg";
import Grass2 from "../../../assets/images/svg/meet-block-grass-2.svg";
import Grass3 from "../../../assets/images/svg/meet-block-grass-3.svg";

interface MeetBlock {
  meetTitle: string;
  meetDescription: string;
}

const MeetBlock: React.FC<{ meetBlockProps: MeetBlock }> = ({
  meetBlockProps,
}) => {
  const { meetTitle, meetDescription } = meetBlockProps;

  return (
    <Root>
      <div className="container">
        <Inner>
          <InfoWrapper>
            <Title>{meetTitle || "Meet title"}</Title>
            <Content>{meetDescription || "Meet description"}</Content>
          </InfoWrapper>
          <StyledMeetBlockIcon />
          <StyledMeetBlockIconForMobile />
          <StyledCloud1 />
          <StyledCloud2 />
          <StyledCloud3 />
          <StyledGrass1 />
          <StyledGrass2 />
          <StyledGrass3 />
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section`
  background-color: var(--white2);
  padding: 0 8.5%;
  @media (max-width: 768px) {
    padding: 0 5.7%;
    padding-bottom: 148px;
  }
`;

const Inner = styled.div`
  position: relative;
  padding-top: 127px;
  padding-bottom: 167.31px;
  @media (max-width: 768px) {
    padding-top: 38px;
    padding-bottom: 67.31px;
  }
`;

const InfoWrapper = styled.div`
  margin-left: 7.2%;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 191px;
  }
`;

const Title = styled.h2`
  width: 40%;
  font-weight: 700;
  font-size: 39px;
  line-height: 46.22px;
  color: var(--gray3);
  @media (max-width: 768px) {
    width: 85%;
    font-size: 24px;
    line-height: 28px;
  }
`;

const Content = styled.p`
  width: 40%;
  margin-top: 26px;
  font-size: 18px;
  line-height: 25.2px;
  color: var(--gray3);
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const StyledMeetBlockIcon = styled(MeetBlockIcon)`
  position: relative;
  left: -12.5%;
  width: 100%;
  margin-top: 63px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledMeetBlockIconForMobile = styled(MeetIconForMobile)`
  position: relative;
  left: 5%;
  width: 100%;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledCloud1 = styled(Cloud1)`
  position: absolute;
  top: 13%;
  left: 92%;
  @media (max-width: 768px) {
    width: 30px;
    left: 43.3%;
    top: 21.8%;
  }
`;

const StyledCloud2 = styled(Cloud2)`
  position: absolute;
  top: 17.7%;
  left: 71%;
  @media (max-width: 768px) {
    width: 53px;
    left: 6.1%;
    top: 23.8%;
  }
`;

const StyledCloud3 = styled(Cloud3)`
  position: absolute;
  top: 20%;
  left: 79%;
  @media (max-width: 768px) {
    width: 160px;
    top: 24.4%;
    left: 21%;
  }
`;

const StyledGrass1 = styled(Grass1)`
  position: absolute;
  top: 54%;
  left: 29%;
  @media (max-width: 768px) {
    width: 105px;
    top: 43.8%;
    left: 8.7%;
  }
`;

const StyledGrass2 = styled(Grass2)`
  position: absolute;
  top: 62.5%;
  left: 78.9%;
  @media (max-width: 768px) {
    width: 84px;
    top: 62.1%;
    left: 72%;
  }
`;

const StyledGrass3 = styled(Grass3)`
  position: absolute;
  top: 76%;
  left: 45.7%;
  @media (max-width: 768px) {
    width: 80px;
    top: 81.7%;
    left: 26.8%;
  }
`;

export default MeetBlock;
