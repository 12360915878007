import * as React from "react";
import { graphql } from "gatsby";

import "../assets/styles/core.scss";
import { MainLayout } from "../layouts/MainLayout";
import {
  OwnBlock,
  IntroBlock,
  CircleBlock,
  PeopleBlock,
} from "../pagesComponents";

const IndexPage = ({ data }) => {
  const { defaultSeo } = data.strapiGlobal;
  const { locale } = data.strapiHomepage;

  return (
    <MainLayout
      locale={locale}
      seo={defaultSeo}
      headerLinks={data.allStrapiHeaderLinks.nodes}
      footerData={data.strapiFooter}
      footerSocialLinks={data.allStrapiFooterSocialLinks.nodes}
    >
      <IntroBlock
        introBlockProps={data.strapiHomepage}
        appLinks={data.strapiAppLinks}
      />
      <OwnBlock ownBlockProps={data.strapiHomepage} />
      <CircleBlock />
      <PeopleBlock data={data.strapiHomepage} appLinks={data.strapiAppLinks} />
    </MainLayout>
  );
};

export const GetIntroBlockContentQuery = graphql`
  query getPageData($locale: String) {
    strapiGlobal {
      siteName
      defaultSeo {
        metaTitle
        metaDescription
      }
    }
    strapiHomepage(locale: { eq: $locale }) {
      locale
      seo {
        metaTitle
        metaDescription
      }
      introTitle
      introDescription
      introImage1 {
        localFile {
          publicURL
        }
        alternativeText
      }
      introImage2 {
        localFile {
          publicURL
        }
        alternativeText
      }
      ownTitleDefaultPart
      ownTitlePurplePart
      ownDescription
      ownImage1 {
        localFile {
          publicURL
        }
        alternativeText
      }
      ownImage2 {
        localFile {
          publicURL
        }
        alternativeText
      }
      ownImage3 {
        localFile {
          publicURL
        }
        alternativeText
      }
      ownImage4 {
        localFile {
          publicURL
        }
        alternativeText
      }
      findPeopleTitle
      findPeopleDescription
      meetTitle
      meetDescription
      interestTitle
      interestDescription
      screenTitle
      screenTitleForMobile
      screenDescriptionForMobile
      screenImage1 {
        localFile {
          publicURL
        }
        alternativeText
      }
      screenImage2 {
        localFile {
          publicURL
        }
        alternativeText
      }
    }
    strapiAppLinks {
      AppStoreLink
      GooglePlayLink
    }
    strapiFooter(locale: { eq: $locale }) {
      contactsColumnTitle
      contactEmail
      contactPhone
      contactPhoneLabel
      officeColumnTitle
      officeAddress
      socialWebsColumnTitle
    }
    allStrapiHeaderLinks(filter: { locale: { eq: $locale } }) {
      nodes {
        id
        href
        label
        sort
      }
    }
    allStrapiFooterSocialLinks {
      nodes {
        id
        href
        iconImage {
          localFile {
            publicURL
          }
          alternativeText
        }
        sort
      }
    }
  }
`;

export default IndexPage;
