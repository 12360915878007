import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ImageData } from "../../../interfaces";
import getWindowDimensions from "../../../hooks/useWindowDimension";

interface OwnBlockProps {
  ownTitleDefaultPart: string;
  ownTitlePurplePart: string;
  ownDescription: string;
  ownImage1: ImageData;
  ownImage2: ImageData;
  ownImage3: ImageData;
  ownImage4: ImageData;
}

const OwnBlock: React.FC<{ ownBlockProps: OwnBlockProps }> = ({
  ownBlockProps,
}) => {
  const {
    ownTitleDefaultPart,
    ownTitlePurplePart,
    ownDescription,
    ownImage1,
    ownImage2,
    ownImage3,
    ownImage4,
  } = ownBlockProps;

  const windowWidth = getWindowDimensions().width;

  gsap.registerPlugin(ScrollTrigger);
  const containerRef = useRef();
  const image1Ref = useRef();
  const image2Ref = useRef();
  const image3Ref = useRef();
  const image4Ref = useRef();

  useEffect(() => {
    const ownBlockTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "center bottom",
        end: "center top",
        scrub: 1,
      },
    });

    if (windowWidth > 968) {
      ownBlockTimeline
        .to(
          image1Ref.current,
          {
            duration: 1,
            y: "-60%",
          },
          0
        )
        .to(
          image2Ref.current,
          {
            duration: 1,
            y: "-10%",
          },
          0
        )
        .to(
          image3Ref.current,
          {
            duration: 1,
            y: "-65%",
          },
          0
        )
        .to(
          image4Ref.current,

          {
            duration: 1,
            y: "-35%",
          },
          0
        );
    } else {
      ownBlockTimeline
        .to(
          image4Ref.current,
          {
            duration: 1,
            y: "-15%",
          },
          0
        )
        .to(
          image3Ref.current,
          {
            duration: 1,
            y: "-85%",
          },
          0
        );
    }
  }, []);

  return (
    <Root ref={containerRef}>
      <div className="container">
        <Inner>
          <InfoWrapper>
            <Title id="about-us">
              {ownTitleDefaultPart || "Own title default"}
              <StyledText>{ownTitlePurplePart || "purple part"}</StyledText>
            </Title>
            <Content>{ownDescription || "Own description"}</Content>
          </InfoWrapper>
          <StyledImage1 ref={image1Ref}>
            <img src={ownImage1?.localFile.publicURL} alt="picnic" />
          </StyledImage1>
          <StyledImage2 ref={image2Ref}>
            <img src={ownImage2?.localFile.publicURL} alt="talking 1" />
          </StyledImage2>
          <StyledImage3 ref={image3Ref}>
            <img src={ownImage3?.localFile.publicURL} alt="talking 2" />
          </StyledImage3>
          <StyledImage4 ref={image4Ref}>
            <img
              width="284"
              height="344"
              src={ownImage4?.localFile.publicURL}
              alt="relaxation on th beach"
            />
          </StyledImage4>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section``;

const Inner = styled.div`
  position: relative;
  padding: 194px 8.5% 403px;

  @media (max-width: 768px) {
    padding: 100px 5% 50px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  max-width: 612px;
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  color: var(--gray3);
  margin-bottom: 30px;
  text-align: center;
  @media (max-width: 1024px) {
    max-width: 400px;
  }
  @media (max-width: 768px) {
    max-width: 307px;
    text-align: left;

    margin: 0 0 20px 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
  }
`;

const StyledText = styled.span`
  margin-left: 15px;
  color: var(--default);
  @media (max-width: 768px) {
    display: block;
    margin-left: 0;
  }
`;

const Content = styled.p`
  width: 50%;
  font-size: 18px;
  line-height: 25px;
  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 60px;
  }
`;

const StyledImage1 = styled.div`
  max-width: 204px;
  width: 100%;
  position: absolute;
  top: 19.4%;
  left: 5.4%;
  border: 4px solid var(--white1);
  border-radius: 27.5px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  @media (max-width: 1240px) {
    max-width: 150px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledImage2 = styled.div`
  max-width: 297px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 26.8%;
  border: 5px solid var(--white1);
  border-radius: 40px;
  box-shadow: 0 7px 23.5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  @media (max-width: 1240px) {
    max-width: 270px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledImage3 = styled.div`
  max-width: 193px;
  width: 100%;
  position: absolute;
  top: 68.2%;
  left: 72%;
  border: 6px solid var(--white1);
  border-radius: 27.5px;
  box-shadow: 0 11px 36px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  @media (max-width: 768px) {
    border: 4px solid var(--white1);
    max-width: 121px;
    top: 68.8%;
    left: 58%;
  }
`;

const StyledImage4 = styled.div`
  max-width: 452px;
  width: 100%;
  position: absolute;
  top: 0.1%;
  left: 85.8%;
  border: 4px solid var(--white1);
  border-radius: 61px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  @media (max-width: 768px) {
    position: relative;
    border: 6px solid var(--white1);
    border-radius: 36px;
    max-width: 284px;
    z-index: -1;
    top: auto;
    left: auto;
  }
  @media (min-width: 1441px) {
    left: 81.8%;
  }
`;

export default OwnBlock;
