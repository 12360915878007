import * as React from "react";
import styled from "styled-components";

import InterestBlockIcon from "../../../assets/images/svg/interest-block-icon.svg";

import Cloud1 from "../../../assets/images/svg/interest-block-cloud-1.svg";
import Cloud2 from "../../../assets/images/svg/interest-block-cloud-2.svg";
import Cloud3 from "../../../assets/images/svg/interest-block-cloud-3.svg";
import Cloud4 from "../../../assets/images/svg/interest-block-cloud-4.svg";
import Cloud5 from "../../../assets/images/svg/interest-block-cloud-5.svg";

import Rectangle from "../../../assets/images/svg/interest-block-rectangle.svg";
import Dot from "../../../assets/images/svg/interest-block-dot.svg";

import Ramp1 from "../../../assets/images/svg/interest-block-ramp-1.svg";
import Ramp2 from "../../../assets/images/svg/interest-block-ramp-2.svg";
import Man from "../../../assets/images/svg/interest-block-man.svg";
import Woman from "../../../assets/images/svg/interest-block-woman.svg";

interface InterestBlockProps {
  interestTitle: string;
  interestDescription: string;
}

const InterestBlock: React.FC<{ interestBlockProps: InterestBlockProps }> = ({
  interestBlockProps,
}) => {
  const { interestTitle, interestDescription } = interestBlockProps;

  return (
    <Root>
      <div className="container">
        <Inner>
          <InfoWrapper>
            <Title>{interestTitle || "Interest title"}</Title>
            <Content>{interestDescription || "Interest description"}</Content>
          </InfoWrapper>
          <StyledInterestBlockIcon />
          <StyledCloud1 />
          <StyledCloud2 />
          <StyledCloud3 />
          <StyledCloud4 />
          <StyledCloud5 />
          <StyledRamp1 />
          <StyledRamp2 />
          <StyledMan />
          <StyledWoman />
          <StyledRectangle />
          <StyledDot />
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section`
  width: 100%;
  margin-bottom: 675px;
  @media (max-width: 768px) {
    margin-bottom: 820px;
  }
  @media (max-width: 425px) {
    margin-bottom: 736px;
  }
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  padding: 0 8.5%;
  padding-top: 8.3%;
  padding-bottom: 20%;
  @media (max-width: 768px) {
    padding: 0 5.6%;
  }
`;

const InfoWrapper = styled.div`
  margin-top: 220.96px;
  @media (max-width: 768px) {
    margin-top: 39px;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 39px;
  line-height: 46.22px;
  margin-bottom: 20px;
  color: var(--gray3);
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Content = styled.p`
  width: 45%;
  font-size: 18px;
  line-height: 25.2px;
  color: var(--gray3);
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    margin-bottom: 0;
  }
`;

const StyledInterestBlockIcon = styled(InterestBlockIcon)`
  position: absolute;
  width: 107.9%;
  top: 63.8%;
  left: -4%;
  z-index: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledCloud1 = styled(Cloud1)`
  position: absolute;
  top: 17.3%;
  left: 41.8%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledCloud2 = styled(Cloud2)`
  position: absolute;
  top: 14.8%;
  left: 22.1%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledCloud3 = styled(Cloud3)`
  position: absolute;
  top: 25.6%;
  left: 82%;
  @media (max-width: 768px) {
    width: 20px;
    top: 96.6%;
    left: 30%;
  }
`;

const StyledCloud4 = styled(Cloud4)`
  position: absolute;
  top: 28.2%;
  left: 85.9%;
  @media (max-width: 768px) {
    width: 30px;
    top: 100%;
    left: 38.7%;
  }
`;

const StyledCloud5 = styled(Cloud5)`
  position: absolute;
  top: 28.5%;
  left: 71.2%;
  @media (max-width: 768px) {
    width: 93px;
    top: 98%;
    left: 6%;
  }
`;

const StyledRectangle = styled(Rectangle)`
  position: absolute;
  top: 16.7%;
  left: 13.2%;
  z-index: -1;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledDot = styled(Dot)`
  position: absolute;
  top: 29.3%;
  left: 16%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledRamp1 = styled(Ramp1)`
  position: absolute;
  top: 195%;
  left: 0;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledRamp2 = styled(Ramp2)`
  position: absolute;
  top: 137.8%;
  display: none;
  @media (max-width: 768px) {
    display: block;
    left: 73%;
  }
  @media (max-width: 375px) {
    display: block;
    left: 43.7%;
  }
`;

const StyledMan = styled(Man)`
  position: absolute;
  top: 173%;
  display: none;
  @media (max-width: 768px) {
    display: block;
    left: 49%;
  }
  @media (max-width: 425px) {
    display: block;
    left: 29%;
  }
`;

const StyledWoman = styled(Woman)`
  position: absolute;
  top: 280%;
  left: 5%;
  display: none;
  @media (max-width: 768px) {
    display: block;
    left: 15%;
  }
  @media (max-width: 425px) {
    display: block;
    left: 5%;
  }
`;

export default InterestBlock;
