import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { AppStoreIconSolid } from "../../../ui/icons/AppStoreIcons";
import { GooglePlayIconSolid } from "../../../ui/icons/GooglePlayIcons";
import { AppLinks, ImageData } from "../../../interfaces";

interface IntroBlockProps {
  introTitle: string;
  introDescription: string;
  introImage1: ImageData;
  introImage2: ImageData;
}

const IntroBlock: React.FC<{
  introBlockProps: IntroBlockProps;
  appLinks: AppLinks;
}> = ({ introBlockProps, appLinks }) => {
  const { introTitle, introDescription, introImage1, introImage2 } =
    introBlockProps;
  gsap.registerPlugin(ScrollTrigger);

  const containerRef = useRef();
  const titleRef = useRef();
  const descriptionRed = useRef();
  const screenOneRef = useRef();
  const screenTwoRef = useRef();
  const linksRef = useRef();
  const bgCircleRef = useRef();

  useEffect(() => {
    const introTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top top",
        end: "bottom top",
        scrub: 1,
      },
    });
    introTimeline
      .fromTo(
        [titleRef.current, descriptionRed.current, linksRef.current],
        { opacity: 1 },
        {
          opacity: 0,
          duration: 1,
        }
      )
      .to(
        screenOneRef.current,
        {
          duration: 1,
          y: "-20%",
          x: "-10%",
        },
        "<"
      )
      .to(
        screenTwoRef.current,
        {
          duration: 1,
          y: "-25%",
          x: "10%",
        },
        "<"
      )
      .to(
        bgCircleRef.current,
        {
          duration: 1,
          x: "20%",
          scale: 1.2,
        },
        "<"
      );
  }, []);

  return (
    <Root ref={containerRef}>
      <div className="container">
        <Inner>
          <svg
            ref={bgCircleRef}
            className="intro-bg-circle"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
          >
            <circle cx="200" cy="200" r="200" fill="#5434F7" />
          </svg>
          <InfoWrapper>
            <Title ref={titleRef}>{introTitle || "Intro title"}</Title>
            <Content ref={descriptionRed}>
              {introDescription || "Intro description"}
            </Content>
            <LinksWrapper ref={linksRef}>
              <StyledLink href={appLinks.AppStoreLink}>
                <AppStoreIconSolid />
              </StyledLink>
              <StyledLink href={appLinks.GooglePlayLink}>
                <GooglePlayIconSolid />
              </StyledLink>
            </LinksWrapper>
          </InfoWrapper>
          <ImagesWrapper>
            <MobileImageWrapper1 ref={screenOneRef}>
              <img
                src={introImage1?.localFile.publicURL}
                alt={introImage1?.alternativeText}
                width="285"
                height="578"
              />
            </MobileImageWrapper1>
            <MobileImageWrapper2 ref={screenTwoRef}>
              <img
                src={introImage2?.localFile.publicURL}
                alt={introImage2?.alternativeText}
                width="285"
                height="578"
              />
            </MobileImageWrapper2>
          </ImagesWrapper>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section`
  width: 100%;
  min-height: 100vh;
`;

const Inner = styled.div`
  position: relative;
  padding: 21% 8.5% 445px;

  display: flex;
  justify-content: space-between;

  .intro-bg-circle {
    position: absolute;
    width: 1517px;
    height: 1517px;
    top: -48.5%;
    left: -26%;
    z-index: -1;
  }
  @media (max-width: 1024px) {
    padding: 21% 8.5% 200px;
    .intro-bg-circle {
      width: 1100px;
      height: 1100px;
      left: -24%;
      top: -100px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 138px 5% 85.3%;
    .intro-bg-circle {
      width: 900px;
      height: 900px;
    }
  }
  @media (max-width: 375px) {
    .intro-bg-circle {
      left: -77%;
      top: -200px;
    }
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 64px;
  line-height: 76px;
  color: var(--white1);
  margin-bottom: 24px;
  @media (max-width: 768px) {
    max-width: 228px;
    width: 100%;
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
  }
`;

const Content = styled.p`
  font-size: 20px;
  color: var(--white1);
  line-height: 28px;
  margin-bottom: 82px;
  max-width: 483px;
  @media (max-width: 768px) {
    max-width: 335px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 34px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 369px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 319px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

const StyledLink = styled.a`
  transition: transform 0.2s;
  svg {
    width: 100%;
  }
  :hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    width: 48%;
    margin-right: 0;
    img {
      width: 100%;
    }
  }
`;

const ImagesWrapper = styled.div`
  position: relative;
  flex: 0 1 50%;
  @media (max-width: 768px) {
    left: 11.5%;
    top: 123%;
    width: 90%;
  }
`;

const MobileImageWrapper1 = styled.div`
  position: absolute;
  right: 42%;
  top: -23%;
  max-width: 285px;
  width: 49%;

  @media (max-width: 1240px) {
    top: 0;
  }
`;

const MobileImageWrapper2 = styled.div`
  position: absolute;
  right: 7%;
  top: -13%;
  max-width: 285px;
  width: 49%;
  transform: rotate(9deg);

  @media (max-width: 1240px) {
    top: 0;
  }
`;

export default IntroBlock;
