import * as React from "react";
import marked from "marked";
import styled from "styled-components";

import {
  AppStoreIconLight,
  AppStoreIconMobile,
} from "../../../ui/icons/AppStoreIcons";
import { GooglePlayIconLight } from "../../../ui/icons/GooglePlayIcons";
import { AppLinks, ImageData } from "../../../interfaces";

interface ScreenBlockProps {
  screenTitle: string;
  screenTitleForMobile: string;
  screenDescriptionForMobile: string;
  screenImage1: ImageData;
  screenImage2: ImageData;
}

const ScreensBlock: React.FC<{
  screensBlockProps: ScreenBlockProps;
  appLinks: AppLinks;
}> = ({ screensBlockProps, appLinks }) => {
  const {
    screenTitle,
    screenTitleForMobile,
    screenDescriptionForMobile,
    screenImage1,
    screenImage2,
  } = screensBlockProps;

  return (
    <Root>
      <div className="container">
        <Inner>
          <ContentWrapper>
            <Title dangerouslySetInnerHTML={{ __html: marked(screenTitle) }} />
            <LinksWrapper>
              <StyledLink href={appLinks.AppStoreLink}>
                <AppStoreIconLight />
              </StyledLink>
              <StyledLink href={appLinks.GooglePlayLink}>
                <GooglePlayIconLight />
              </StyledLink>
            </LinksWrapper>
          </ContentWrapper>
          <ContentWrapperForMobile>
            <Title>
              <b>{screenTitleForMobile || "Screen title"}</b>
            </Title>
            <InfoText>
              {screenDescriptionForMobile || "Screen description"}
            </InfoText>
            <LinksWrapper>
              <StyledLink href={appLinks.AppStoreLink}>
                <StyledAppStoreIcon />
                <StyledAppStoreMobile />
              </StyledLink>
              <StyledLink href={appLinks.GooglePlayLink}>
                <GooglePlayIconLight />
              </StyledLink>
            </LinksWrapper>
          </ContentWrapperForMobile>
          <MobilesWrapper>
            <StyledMobile1>
              <img
                src={screenImage1?.localFile.publicURL}
                alt={screenImage1?.alternativeText}
              />
            </StyledMobile1>
            <StyledMobile2>
              <img
                src={screenImage2?.localFile.publicURL}
                alt={screenImage2?.alternativeText}
              />
            </StyledMobile2>
          </MobilesWrapper>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section`
  width: 100%;
  padding: 0 5.6%;
  margin-bottom: 324px;
  @media (max-width: 768px) {
    margin-bottom: 260px;
  }
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  background-color: var(--default);
  border-radius: 24px;
  padding: 100px 80px;
  @media (max-width: 768px) {
    padding: 11% 8%;
    padding-bottom: 163px;
  }
`;

const ContentWrapper = styled.div`
  flex: 0 1 33%;
  @media (max-width: 425px) {
    display: none;
  }
`;

const ContentWrapperForMobile = styled.div`
  display: none;
  @media (max-width: 425px) {
    display: block;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: var(--white3);
  line-height: 31px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 12px;
  }
`;

const InfoText = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: var(--white3);
  margin-bottom: 24px;
`;

const LinksWrapper = styled.div`
  max-width: 340px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

const StyledLink = styled.a`
  width: 45%;
  transition: transform 0.5s;
  margin-bottom: 24px;
  :hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
  }
`;

const StyledAppStoreIcon = styled(AppStoreIconLight)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledAppStoreMobile = styled(AppStoreIconMobile)`
  @media (min-width: 769px) {
    display: none;
  }
`;

const MobilesWrapper = styled.div`
  flex: 0 1 60%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledMobile1 = styled.div`
  width: 22%;
  position: absolute;
  top: -28.8%;
  left: 48.6%;
  @media (max-width: 768px) {
    top: -5%;
  }
`;

const StyledMobile2 = styled.div`
  width: 22%;
  position: absolute;
  top: -13%;
  left: 72.7%;
  @media (max-width: 768px) {
    top: 0%;
  }
`;

export default ScreensBlock;
