module.exports = {
  ru: {
    path: "ru",
    locale: "Russian",
    default: true,
  },
  en: {
    path: "en",
    locale: "English",
  },
};
