import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, StaticQuery, graphql } from "gatsby";

import { HeaderLogoIcon, MenuIcon, CloseIcon } from "../../ui/icons";
import useWindowDimension from "../../hooks/useWindowDimension";
import { HeaderLink } from "../../interfaces";

interface HeaderProps {
  locale: string;
  headerLinks: HeaderLink[];
}

const Header: FC<HeaderProps> = ({ locale, headerLinks }) => {
  const [isMobileMenuShowed, setShowMobileMenu] = useState<boolean>(false);

  const windowWidth = useWindowDimension().width;

  useEffect(() => {
    if (isMobileMenuShowed) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isMobileMenuShowed]);

  const links = headerLinks
    .sort((first, second) => first.sort - second.sort)
    .map((value) => (
      <StyledLink key={`header-link-${value.id}`} to={value.href}>
        {value.label}
      </StyledLink>
    ));

  return (
    <Root id="header">
      <div className="container">
        <Inner>
          <LogoWrapper>
            <HeaderLogoIcon />
          </LogoWrapper>
          <MenuButton onClick={() => setShowMobileMenu(true)}>
            <StyledMenuIcon />
          </MenuButton>
          {windowWidth && windowWidth <= 768 && (
            <StyledMenu isVisible={isMobileMenuShowed}>
              <CloseButton onClick={() => setShowMobileMenu(false)}>
                <CloseIcon />
              </CloseButton>
              <LogoWrapper>
                <HeaderLogoIcon />
              </LogoWrapper>
              <MobileLinksWrapper>{links}</MobileLinksWrapper>
            </StyledMenu>
          )}
          <LinksWrapper>{links}</LinksWrapper>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.header`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 37px 8.5%;
  @media (max-width: 768px) {
    padding: 32px 20px;
    justify-content: space-between;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10.5%;

  @media (max-width: 768px) {
    width: 70%;
    margin-top: 10px;
    margin-right: 0;
  }
`;

const LinksWrapper = styled.ul`
  max-width: 372px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
`;
const MobileLinksWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24.8px;
  color: var(--link-color);
  transition: color 0.3s;
  :hover {
    color: var(--hover-link-color);
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const MenuButton = styled.button`
  background-color: var(--default);
  border-radius: 10px;
  transition: background-color 0.15s;
  :focus {
    background-color: var(--defaultLight);
  }
  :hover {
    background-color: var(--defaultLight);
  }
  :active {
    background-color: var(--defaultDark);
  }
`;

const CloseButton = styled.button`
  background-color: var(--default);
  border-radius: 10px;
  transition: background-color 0.15s;
  :focus {
    background-color: var(--defaultLight);
  }
  :hover {
    background-color: var(--defaultLight);
  }
  :active {
    background-color: var(--defaultDark);
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledMenu = styled.div<{ isVisible: boolean }>`
  transition: transform 0.5s;
  transform: ${(props) =>
    props.isVisible ? "translateX(0)" : "translateX(115%)"};
  padding: 3%;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  max-width: 100vh;
  width: 100%;
  min-height: 100vh;
  background-color: var(--default);
`;

export default Header;
