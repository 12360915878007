import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import marked from "marked";
import { AppLinks, ImageData } from "../../../interfaces";
import { AppStoreIconMobile } from "../../../ui/icons/AppStoreIcons";
import { GooglePlayIconLight } from "../../../ui/icons/GooglePlayIcons";
import getWindowDimensions from "../../../hooks/useWindowDimension";

import FindGreenBall from "../../../assets/images/svg/find-people-block-ball-1.svg";
import FindBlueBall from "../../../assets/images/svg/find-people-block-ball-3.svg";
import FindEllipse from "../../../assets/images/svg/find-people-block-ellipse-1.svg";
import FindPeopleIconForMobile from "../../../assets/images/svg/find-people-block-for-mobile.svg";
import FindAnimPath from "../../../assets/images/svg/find-anim-path.svg";
import FindAnimPathBall from "../../../assets/images/svg/find-people-block-ball-2.svg";
import FindMain from "../../../assets/images/svg/find-main.svg";

import MeetBlockIcon from "../../../assets/images/svg/meet-block-icon.svg";
import MeetGrass1 from "../../../assets/images/svg/meet-block-grass-1.svg";
import MeetGrass2 from "../../../assets/images/svg/meet-block-grass-2.svg";
import MeetGrass3 from "../../../assets/images/svg/meet-block-grass-3.svg";
import MeetCloud1 from "../../../assets/images/svg/meet-block-cloud-1.svg";
import MeetCloud2 from "../../../assets/images/svg/meet-block-cloud-2.svg";
import MeetCloud3 from "../../../assets/images/svg/meet-block-cloud-3.svg";

import { MeetIconForMobile } from "../../../ui/icons";

import InterestRectangle from "../../../assets/images/svg/interest-block-rectangle.svg";
import InterestMain from "../../../assets/images/svg/interest-block-icon.svg";
import InterestMobileMain from "../../../assets/images/svg/people-interest-mobile.svg";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);

interface PeopleProps {
  findPeopleTitle: string;
  findPeopleDescription: string;
  meetTitle: string;
  meetDescription: string;
  interestTitle: string;
  interestDescription: string;
  screenTitle: string;
  screenTitleForMobile: string;
  screenDescriptionForMobile: string;
  screenImage1: ImageData;
  screenImage2: ImageData;
}

const PeopleBlock: React.FC<{
  data: PeopleProps;
  appLinks: AppLinks;
}> = ({ data, appLinks }) => {
  const {
    findPeopleTitle,
    findPeopleDescription,
    meetTitle,
    meetDescription,
    interestTitle,
    interestDescription,
    screenTitle,
    screenTitleForMobile,
    screenDescriptionForMobile,
    screenImage1,
    screenImage2,
  } = data;
  const windowWidth = getWindowDimensions().width;

  const containerRef = useRef();
  const findBlockTitleRef = useRef();
  const findBlockDescriptionRef = useRef();
  const findBlockBgCircleRef = useRef();
  const findBlockBallGreenRef = useRef();
  const findBlockBallBlueRef = useRef();
  const findBlockMainSvgRef = useRef();

  const findBlockMainMobSvgRef = useRef();

  const meetBlockTitleRef = useRef();
  const meetBlockDescriptionRef = useRef();
  const meetBlockMainSvgRef = useRef();
  const meetBlockCloud1Ref = useRef();
  const meetBlockCloud2Ref = useRef();
  const meetBlockCloud3Ref = useRef();

  const meetBlockMainMobileSvgRef = useRef();

  const interestsBlockTitleRef = useRef();
  const interestsBlockDescriptionRef = useRef();
  const interestsBlockMainSvgRef = useRef();
  const interestsBlockRectSvgRef = useRef();

  const interestsBlockMainMobSvgRef = useRef();

  const screensBlockRef = useRef();
  const screensBlockFirstMobileRef = useRef();
  const screensBlockSecondMobileRef = useRef();

  useEffect(() => {
    if (windowWidth > 968) {
      const peopleDeskTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          scrub: 1,
          pin: containerRef.current,
          pinSpacing: "margin",
          start: "top top",
          end: "+=7000px bottom",
        },
      });

      peopleDeskTimeline
        .to(document.querySelector("#anime-ball"), {
          motionPath: {
            path: "#red-ball-path",
            align: "#red-ball-path",
            alignOrigin: [0.5, 0.5],
          },
          duration: 20,
        })
        .to(
          findBlockBallGreenRef.current,
          {
            y: "-150px",
            x: "100px",
            duration: 10,
            opacity: 0,
            ease: "Power3.easeIn",
          },
          ">"
        )
        .to(
          findBlockBallBlueRef.current,
          {
            y: "100px",
            x: "-150px",
            opacity: 0,
            duration: 10,
            ease: "Power3.easeIn",
          },
          "<"
        )
        .to(
          [findBlockTitleRef.current, findBlockDescriptionRef.current],
          {
            opacity: 0,
            duration: 4,
            display: "none",
          },
          ">"
        )
        .to(
          findBlockMainSvgRef.current,
          {
            opacity: 0,
            display: "none",
            duration: 4,
          },
          "<"
        )
        .to(
          findBlockBgCircleRef.current,
          {
            scale: 5,
            duration: 20,
          },
          "<"
        )
        .to(
          [meetBlockTitleRef.current, meetBlockDescriptionRef.current],
          {
            display: "block",
            opacity: 1,
            duration: 4,
          },
          ">+=1"
        )
        .to(
          meetBlockMainSvgRef.current,
          {
            display: "block",
            opacity: 1,
            duration: 4,
          },
          "<"
        )
        .fromTo(
          meetBlockCloud1Ref.current,
          {
            x: "40%",
          },
          {
            x: "-80%",
            duration: 8,
          },
          "<"
        )
        .fromTo(
          meetBlockCloud2Ref.current,
          {
            x: "40%",
          },
          {
            x: "-90%",
            duration: 8,
          },
          "<"
        )
        .fromTo(
          meetBlockCloud3Ref.current,
          {
            x: "20%",
          },
          {
            x: "-40%",
            duration: 8,
          },
          "<"
        )
        .to(
          [meetBlockTitleRef.current, meetBlockDescriptionRef.current],
          {
            display: "none",
            opacity: 0,
            duration: 4,
          },
          ">+=4"
        )
        .to(
          meetBlockMainSvgRef.current,
          {
            display: "none",
            opacity: 0,
            duration: 4,
            zIndex: -1,
          },
          ">-=2"
        )
        .to(
          findBlockBgCircleRef.current,
          {
            scale: 0,
            x: "-85%",
            y: "-20%",
            duration: 10,
          },
          "<"
        )
        .to(
          interestsBlockRectSvgRef.current,
          {
            display: "block",
            opacity: 1,
            duration: 11,
          },
          "<"
        )
        .to(
          interestsBlockMainSvgRef.current,
          {
            display: "block",
            opacity: 1,
            duration: 4,
          },
          "<+=2"
        )
        .to(
          [
            interestsBlockTitleRef.current,
            interestsBlockDescriptionRef.current,
          ],
          {
            display: "block",
            opacity: 1,
            duration: 4,
          },
          "<"
        )
        .to(
          [
            interestsBlockTitleRef.current,
            interestsBlockDescriptionRef.current,
          ],
          {
            display: "none",
            opacity: 0,
            duration: 8,
          },
          ">+=10"
        )
        .to(
          interestsBlockMainSvgRef.current,
          {
            display: "none",
            opacity: 0,
            duration: 8,
          },
          "<"
        )
        .to(
          interestsBlockRectSvgRef.current,
          {
            opacity: 0,
            display: "none",
            duration: 8,
          },
          "<"
        )
        .to(
          findBlockBgCircleRef.current,
          {
            scale: 1.6,
            duration: 16,
          },
          "<"
        )
        .to(
          findBlockBgCircleRef.current,
          {
            x: "-37%",
            y: "26%",
            duration: 20,
          },
          ">-=2"
        )
        .to(
          screensBlockRef.current,
          {
            display: "flex",
            opacity: 1,
            duration: 8,
          },
          "<"
        )
        .fromTo(
          screensBlockFirstMobileRef.current,
          {
            y: "-30%",
          },
          {
            y: "-0%",
            ease: "Back.easeOut",
            duration: 14,
          },
          "<"
        )
        .fromTo(
          screensBlockSecondMobileRef.current,
          {
            y: "-24%",
          },
          {
            y: "-0%",
            ease: "Back.easeOut",
            duration: 14,
          },
          "<"
        );
    } else {
      const peopleMobTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          scrub: 1,
          pin: true,
          pinSpacing: true,

          start: "top top",
          end: "+=3000 bottom",
        },
      });

      peopleMobTimeline
        .to(
          [findBlockTitleRef.current, findBlockDescriptionRef.current],
          {
            opacity: 0,
            duration: 4,
            display: "none",
          },
          ">"
        )
        .to(
          findBlockMainMobSvgRef.current,
          {
            opacity: 0,
            display: "none",
            duration: 4,
          },
          "<"
        )
        .to(
          findBlockBgCircleRef.current,
          {
            display: "block",
            scale: 2,
            duration: 10,
          },
          "<"
        )
        .to(
          [meetBlockTitleRef.current, meetBlockDescriptionRef.current],
          {
            display: "block",
            opacity: 1,
            duration: 6,
          },
          ">+=4"
        )
        .to(
          meetBlockMainMobileSvgRef.current,
          {
            opacity: 1,
            display: "flex",
            duration: 6,
          },
          "<"
        )
        .to(
          [meetBlockTitleRef.current, meetBlockDescriptionRef.current],
          {
            opacity: 0,
            duration: 8,
            display: "none",
          },
          ">"
        )
        .to(
          meetBlockMainMobileSvgRef.current,
          {
            opacity: 0,
            display: "none",
            duration: 8,
          },
          "<"
        )
        .to(
          findBlockBgCircleRef.current,
          {
            scale: 0,
            display: "none",

            duration: 10,
          },
          "<"
        )
        .to(
          [
            interestsBlockTitleRef.current,
            interestsBlockDescriptionRef.current,
          ],
          {
            display: "block",
            opacity: 1,
            duration: 8,
          },
          ">+=4"
        )
        .to(
          interestsBlockMainMobSvgRef.current,
          {
            opacity: 1,
            display: "flex",
            duration: 8,
          },
          "<"
        )
        .to(
          [
            interestsBlockTitleRef.current,
            interestsBlockDescriptionRef.current,
          ],
          {
            opacity: 0,
            duration: 8,
            display: "none",
          },
          ">"
        )
        .to(
          interestsBlockMainMobSvgRef.current,
          {
            opacity: 0,
            display: "none",
            duration: 8,
          },
          "<"
        )
        .to(
          screensBlockRef.current,
          {
            display: "flex",
            opacity: 1,
            duration: 8,
          },
          ">"
        )
        .to(
          findBlockBgCircleRef.current,
          {
            scale: 1,
            display: "block",
            y: "60%",
            x: "-35%",
            duration: 10,
          },
          "<"
        );
    }
  }, []);

  return (
    <Root ref={containerRef}>
      <div className="container">
        <Inner>
          <InfoWrapper>
            <FindTitle ref={findBlockTitleRef} id="advantage">{findPeopleTitle}</FindTitle>
            <FindDescription ref={findBlockDescriptionRef}>
              {findPeopleDescription}
            </FindDescription>

            <MeetTitle ref={meetBlockTitleRef}>{meetTitle}</MeetTitle>
            <MeetDescription ref={meetBlockDescriptionRef}>
              {meetDescription}
            </MeetDescription>

            <InterestsTitle ref={interestsBlockTitleRef}>
              {interestTitle}
            </InterestsTitle>
            <InterestsDescription ref={interestsBlockDescriptionRef}>
              {interestDescription}
            </InterestsDescription>
          </InfoWrapper>

          <MainFindSvgWrapper ref={findBlockMainSvgRef}>
            <StyledFindAnimPath />
            <StyledFindAnimPathBall />
            <StyledFindMain />
          </MainFindSvgWrapper>

          <Image2Wrapper ref={findBlockMainMobSvgRef}>
            <FindPeopleIconForMobile />
          </Image2Wrapper>

          <MainMeetSvgWrapper ref={meetBlockMainSvgRef}>
            <MeetCloudWrapper1 ref={meetBlockCloud1Ref}>
              <MeetCloud1 />
            </MeetCloudWrapper1>
            <MeetCloudWrapper2 ref={meetBlockCloud2Ref}>
              <MeetCloud2 />
            </MeetCloudWrapper2>
            <MeetCloudWrapper3 ref={meetBlockCloud3Ref}>
              <MeetCloud3 />
            </MeetCloudWrapper3>

            <StyledMeetGrass1 />
            <StyledMeetGrass2 />
            <StyledMeetGrass3 />
            <StyledMeetBlockIcon />
          </MainMeetSvgWrapper>

          <MeetMobileSvgWrapper ref={meetBlockMainMobileSvgRef}>
            <MeetIconForMobile />
          </MeetMobileSvgWrapper>

          <GreenBallWrapper ref={findBlockBallGreenRef}>
            <FindGreenBall />
          </GreenBallWrapper>
          <BlueBallWrapper ref={findBlockBallBlueRef}>
            <FindBlueBall />
          </BlueBallWrapper>

          <EllipseWrapper ref={findBlockBgCircleRef}>
            <FindEllipse />
          </EllipseWrapper>

          <InterestRectangleWrapper ref={interestsBlockRectSvgRef}>
            <InterestDot />
            <InterestRectangle />
          </InterestRectangleWrapper>

          <InterestMainWrapper ref={interestsBlockMainSvgRef}>
            <StyledInterestMain />
          </InterestMainWrapper>

          <InterestMainMobWrapper ref={interestsBlockMainMobSvgRef}>
            <InterestMobileMain />
          </InterestMainMobWrapper>

          <Screens ref={screensBlockRef}>
            <ScreensContentWrapper>
              <ScreensTitle
                dangerouslySetInnerHTML={{ __html: marked(screenTitle) }}
              />
              <ScreensContentWrapperForMobile>
                <ScreensMobTitle>
                  <b>{screenTitleForMobile || "Screen title"}</b>
                </ScreensMobTitle>
                <ScreensInfoText>
                  {screenDescriptionForMobile || "Screen description"}
                </ScreensInfoText>
              </ScreensContentWrapperForMobile>
              <ScreensLinksWrapper>
                <ScreensStyledLink href={appLinks.AppStoreLink}>
                  <AppStoreIconMobile />
                </ScreensStyledLink>
                <ScreensStyledLink href={appLinks.GooglePlayLink}>
                  <GooglePlayIconLight />
                </ScreensStyledLink>
              </ScreensLinksWrapper>
            </ScreensContentWrapper>

            <ScreensMobilesWrapper>
              <ScreensStyledMobile1 ref={screensBlockFirstMobileRef}>
                <img
                  src={screenImage1?.localFile.publicURL}
                  alt="Phone screen fllint app - intersections"
                />
              </ScreensStyledMobile1>
              <ScreensStyledMobile2 ref={screensBlockSecondMobileRef}>
                <img
                  src={screenImage2?.localFile.publicURL}
                  alt="Phone screen fllint app - you liked"
                />
              </ScreensStyledMobile2>
            </ScreensMobilesWrapper>
          </Screens>
        </Inner>
      </div>
    </Root>
  );
};

const Root = styled.section`
  width: 100%;
  margin-bottom: 50px;
  position: relative;
`;

const Inner = styled.div`
  position: relative;
  padding: 5% 3.1%;
  @media (max-width: 768px) {
    padding: 5% 0;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  margin-bottom: 65.5px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const titlesStyles = css`
  position: relative;
  font-weight: 700;
  font-size: 39px;
  line-height: 46.22px;
  color: var(--gray3);
  z-index: 10;
  padding: 0 20px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const descriptionStyles = css`
  position: relative;
  max-width: 486px;
  width: 100%;
  margin-top: 26px;
  font-size: 18px;
  line-height: 25.2px;
  color: var(--gray3);
  z-index: 10;
  padding: 0 20px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
  }
`;

/* FIND */

const FindTitle = styled.h2`
  ${titlesStyles}
`;

const FindDescription = styled.p`
  ${descriptionStyles}
`;

const Image2Wrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    svg {
      width: 100%;
      height: auto;
      max-height: 65vh;
    }

    display: block;
  }
`;

const GreenBallWrapper = styled.div`
  position: absolute;
  top: 28.3%;
  left: 70%;
  width: 62px;
  height: 62px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BlueBallWrapper = styled.div`
  position: absolute;
  top: -3.35%;
  left: 57%;
  width: 60px;
  height: 60px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MainFindSvgWrapper = styled.div`
  position: relative;
  max-width: 1132px;
  margin: 0 auto;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledFindMain = styled(FindMain)`
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledFindAnimPath = styled(FindAnimPath)`
  position: absolute;
  top: -75px;

  left: 27%;
  max-width: 584px;
  width: 50%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledFindAnimPathBall = styled(FindAnimPathBall)`
  position: absolute;
  top: 2%;
  left: 26%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const EllipseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 64.5%;
  z-index: -1;
  @media (max-width: 768px) {
    display: none;
  }
`;

/* MEET */

const MeetTitle = styled.h2`
  ${titlesStyles}
  display: none;
  opacity: 0;
`;

const MeetDescription = styled.p`
  ${descriptionStyles}
  display: none;
  opacity: 0;
`;

const MainMeetSvgWrapper = styled.div`
  display: none;
  opacity: 0;
  position: relative;
  width: 100%;
  max-width: 935px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledMeetBlockIcon = styled(MeetBlockIcon)`
  position: relative;
  width: 100%;
  z-index: 10;
`;

const StyledMeetGrass1 = styled(MeetGrass1)`
  position: absolute;
  z-index: 1;
  top: 43%;
  left: 39%;
  @media (max-width: 768px) {
    width: 105px;
    top: 43.8%;
    left: 8.7%;
  }
`;

const StyledMeetGrass2 = styled(MeetGrass2)`
  position: absolute;
  z-index: 1;
  top: 57.5%;
  right: -15%;
  @media (max-width: 768px) {
    width: 84px;
    top: 62.1%;
    left: 72%;
  }
`;

const StyledMeetGrass3 = styled(MeetGrass3)`
  position: absolute;
  z-index: 15;
  top: 76%;
  left: 58.7%;
  @media (max-width: 768px) {
    width: 80px;
    top: 81.7%;
    left: 26.8%;
  }
`;

const MeetCloudWrapper1 = styled.div`
  position: absolute;
  z-index: 1;
  top: -30%;
  right: -25%;
  @media (max-width: 768px) {
    width: 30px;
    right: -43.3%;
    top: 21.8%;
  }
`;

const MeetCloudWrapper2 = styled.div`
  position: absolute;
  z-index: 1;
  top: -17.7%;
  right: -7%;
  @media (max-width: 768px) {
    width: 53px;
    right: -6.1%;
    top: 23.8%;
  }
`;

const MeetCloudWrapper3 = styled.div`
  position: absolute;
  z-index: 1;
  top: -20%;
  right: -39%;
  @media (max-width: 768px) {
    width: 160px;
    top: 24.4%;
    right: -21%;
  }
`;

const MeetMobileSvgWrapper = styled.div`
  display: none;
  justify-content: center;
  position: relative;
  width: 100%;
  svg {
    width: 100%;
    height: auto;
    max-height: 65vh;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

/* INTERESTS */

const InterestsTitle = styled.h2`
  ${titlesStyles}
  display: none;
  opacity: 0;
`;

const InterestsDescription = styled.p`
  ${descriptionStyles}
  display: none;
  opacity: 0;
`;

const InterestMainWrapper = styled.div`
  position: relative;
  width: 100%;
  top: 90%;
  left: -8%;
  display: none;
  opacity: 0;
  z-index: 20;
  @media (max-width: 1400px) {
    left: 0;
    transform: translateX(-20%);
  }
  @media (max-width: 1140px) {
    transform: translateX(-40%);
  }
  @media (max-width: 1024px) {
    transform: translateX(-30%);
  }
`;

const StyledInterestMain = styled(InterestMain)`
  @media (max-width: 1024px) {
    max-width: 1200px;
    height: auto;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const InterestRectangleWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 13%;

  opacity: 0;
  z-index: 1;
  @media (max-width: 1024px) {
    left: -20%;
  }
`;

const InterestDot = styled.div`
  position: absolute;
  top: 16.5%;
  left: 5.5%;

  width: 12px;
  height: 12px;
  background: #ae56dd;
  border-radius: 50%;
`;

const InterestMainMobWrapper = styled.div`
  display: none;
  svg {
    width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

/* SCREENS */

const Screens = styled.div`
  display: none;
  position: relative;
  width: 100%;
  background-color: var(--default);
  border-radius: 24px;
  padding: 100px 80px;
  opacity: 0;
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    margin: 0 auto;
    padding: 36px 28px 163px 28px;
  }
`;

const ScreensContentWrapper = styled.div`
  flex: 0 1 33%;
  @media (max-width: 768px) {
    flex: 0 1 100%;
  }
`;

const ScreensContentWrapperForMobile = styled.div`
  display: none;
  @media (max-width: 425px) {
    display: block;
  }
`;

const ScreensTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: var(--white3);
  line-height: 31px;
  margin-bottom: 32px;
  @media (max-width: 425px) {
    display: none;
  }
`;

const ScreensInfoText = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: var(--white3);
  margin-bottom: 24px;
`;

const ScreensLinksWrapper = styled.div`
  max-width: 340px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

const ScreensStyledLink = styled.a`
  width: 45%;
  transition: transform 0.5s;
  margin-bottom: 24px;
  :hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
  }
`;

const ScreensMobilesWrapper = styled.div`
  flex: 0 1 60%;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ScreensMobTitle = styled.p`
  display: none;
  @media (max-width: 768px) {
    margin-bottom: 32px;
    color: var(--white3);
    display: block;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 12px;
  }
`;

const ScreensStyledMobile1 = styled.div`
  width: 22%;
  position: absolute;
  top: -28.8%;
  left: 48.6%;
  @media (max-width: 768px) {
    top: -5%;
  }
`;

const ScreensStyledMobile2 = styled.div`
  width: 22%;
  position: absolute;
  top: -13%;
  left: 72.7%;
  @media (max-width: 768px) {
    top: 0%;
  }
`;

export default PeopleBlock;
