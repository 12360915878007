import React, { FC } from "react";
import { Helmet } from "react-helmet";

interface Seo {
  title: string;
  description: string;
  image: string;
  article: boolean;
}
interface SeoProps {
  seo?: Seo;
  locale: string;
}

const SEO: FC<SeoProps> = ({
  seo = {
    title: null,
    description: null,
    image: null,
    article: false,
  },
  locale,
}) => {
  const defaultSeo = {
    metaTitle: "Fllint",
    metaDescription: "Fllint",
    shareImage: null,
  };

  const fullSeo = { ...defaultSeo, ...seo };

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.localFile.publicURL;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      });
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={fullSeo.metaTitle}
      htmlAttributes={{
        lang: locale,
      }}
      meta={metaTags}
    />
  );
};

export default SEO;
